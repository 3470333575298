var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "competition h-96px my-1",
      class: _vm.data.block,
      attrs: { id: "competition-type1" }
    },
    [
      _c(
        "div",
        [
          _c(
            "swiper",
            {
              ref: "gameItem",
              staticClass: "mySwiper px-4",
              attrs: { options: _vm.swiperOption }
            },
            _vm._l(_vm.competitions, function(game) {
              return _c(
                "swiper-slide",
                { key: game._id, staticClass: "game__item" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "game__item--card cursor-pointer d-flex justify-center align-center rounded h-96px px-2",
                      style: _vm.bg[_vm.theme]
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("v-img", {
                            staticClass: "rounded",
                            attrs: {
                              src: game.game_logo,
                              "max-height": "30",
                              "max-width": "30"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-80 caption d-flex align-start flex-column ml-1"
                        },
                        [
                          _c("div", { staticClass: "time primary--text" }, [
                            _vm._v(" " + _vm._s(game.start_datetime) + " ")
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "game-name title--text font-weight-bold mt-1 h-40px"
                            },
                            [_vm._v(" " + _vm._s(game.competition_name) + " ")]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            }),
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }